<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import Modal from '@/components/common/Modal.vue';
import Divider from '@/components/common/Divider.vue';
import FormGroup from '@/components/common/FormGroup.vue';
import customersServices from '@/services/customers.services';
import useDebounce from '@/composables/useDebounce';

import SearchCustomerResult from './SearchCustomerResult.vue';

const initialFormData = {
  firstname: '',
  lastname: '',
  email: '',
  postalCode: '',
};

defineProps({
  visible: { type: Boolean, required: true },
});

defineEmits(['close', 'submit']);

const { t } = useI18n();

const formData = ref({ ...initialFormData });
const customers = ref([]);

const searchCustomer = async (searchParameters) => {
  const { firstname, lastname, email, postalCode } = searchParameters;

  if (!firstname.trim() && !lastname.trim() && !email.trim() && !postalCode.trim()) {
    customers.value = [];

    return;
  }

  try {
    customers.value = await customersServices.searchCustomers(
      firstname,
      lastname,
      email,
      postalCode,
    );
  } catch {
    // Ignore the error
  }
};

const debouncedSearchCustomer = useDebounce(searchCustomer);
</script>

<template>
  <Modal
    :isDisplayed="visible"
    :title="t('components.searchCustomerModal.title')"
    :contentClassname="$style.modalContent"
    @close-modal="$emit('close')"
  >
    <div :class="$style.wrapper">
      <form :class="$style.form">
        <FormGroup
          name="firstname"
          :label="t('components.searchCustomerModal.fields.firstname')"
          changeEventStrategy="input"
          v-model="formData.firstname"
          @update:modelValue="debouncedSearchCustomer(formData)"
        />
        <FormGroup
          name="lastname"
          :label="t('components.searchCustomerModal.fields.lastname')"
          changeEventStrategy="input"
          v-model="formData.lastname"
          @update:modelValue="debouncedSearchCustomer(formData)"
        />
        <FormGroup
          name="email"
          :label="t('components.searchCustomerModal.fields.email')"
          changeEventStrategy="input"
          v-model="formData.email"
          @update:modelValue="debouncedSearchCustomer(formData)"
        />
        <FormGroup
          name="postalCode"
          :label="t('components.searchCustomerModal.fields.postalCode')"
          changeEventStrategy="input"
          v-model="formData.postalCode"
          @update:modelValue="debouncedSearchCustomer(formData)"
        />
      </form>

      <Divider class="mt-2 mb-4" />

      <SearchCustomerResult
        selectable
        :customers="customers"
        @customerClick="$emit('submit', $event.id)"
      >
        <template v-slot:title="{ c }">
          {{ c.lastname }} {{ c.firstname }}
        </template>

        <template v-slot:subTitle="{ c }">
          {{ c.policyComplientEmail }}
        </template>
      </SearchCustomerResult>
    </div>
  </Modal>
</template>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.modalContent {
  width: 100%;

  @include xs {
    width: 50rem;
  }
}

.form {
  @include spacing-children('vertical', 1rem);
}
</style>
