<template>
  <div>
    <video ref="video" autoplay hidden></video>
    <canvas ref="canvasElement" hidden style="width: 100%"></canvas>
  </div>
</template>

<script>
import { defineComponent, nextTick, onMounted, onUnmounted, ref, toRefs, watch } from 'vue';
import jsQr from 'jsqr';

const QrCodeScanner = defineComponent({
  emits: ['decode'],
  props: {
    paused: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { paused } = toRefs(props);

    const stream = ref(null);
    const video = ref(null);
    const canvasElement = ref(null);
    const canvas = ref(null);

    const requestAnimationFrameId = ref(null);

    const drawLine = (begin, end, color) => {
      canvas.value.beginPath();
      canvas.value.moveTo(begin.x, begin.y);
      canvas.value.lineTo(end.x, end.y);
      canvas.value.lineWidth = 4;
      canvas.value.strokeStyle = color;
      canvas.value.stroke();
    };

    const decodeQrCode = (imageData) => {
      try {
        const code = jsQr(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert',
        });

        if (code) {
          const {
            topLeftCorner,
            topRightCorner,
            bottomRightCorner,
            bottomLeftCorner,
          } = code.location;

          const color = '#FF3B68';

          drawLine(topLeftCorner, topRightCorner, color);
          drawLine(topRightCorner, bottomRightCorner, color);
          drawLine(bottomRightCorner, bottomLeftCorner, color);
          drawLine(bottomLeftCorner, topLeftCorner, color);

          if (code.data) {
            emit('decode', code.data);
          }
        }
      } catch {
        // DO nothing
      }
    };

    const tick = () => {
      if (video.value?.readyState === video.value?.HAVE_ENOUGH_DATA) {
        canvasElement.value.hidden = false;

        canvasElement.value.height = video.value.videoHeight;
        canvasElement.value.width = video.value.videoWidth;

        canvas.value.drawImage(
          video.value,
          0,
          0,
          canvasElement.value.width,
          canvasElement.value.height,
        );
        const imageData = canvas.value.getImageData(
          0,
          0,
          canvasElement.value.width,
          canvasElement.value.height,
        );

        decodeQrCode(imageData);
      }

      requestAnimationFrameId.value = requestAnimationFrame(tick);
    };

    const pause = () => cancelAnimationFrame(requestAnimationFrameId.value);

    const resume = () => {
      requestAnimationFrameId.value = requestAnimationFrame(tick);
    };

    watch(paused, (value) => {
      if (!value) {
        resume();

        return;
      }

      pause();
    });

    onMounted(() => {
      nextTick(() => {
        canvas.value = canvasElement.value.getContext('2d');

        navigator.mediaDevices
          .getUserMedia({ video: { facingMode: 'environment' } })
          .then((s) => {
            stream.value = s;
            video.value.srcObject = s;
            video.value.setAttribute('playsinline', true);
            video.value.play();

            requestAnimationFrameId.value = requestAnimationFrame(tick);
          });
      });
    });

    onUnmounted(() => {
      cancelAnimationFrame(requestAnimationFrameId.value);

      stream.value.getTracks().forEach((track) => {
        if (track.readyState === 'live' && track.kind === 'video') {
          track.stop();
        }
      });
    });

    return {
      canvasElement,
      video,
    };
  },
});

export default QrCodeScanner;
</script>

<style lang="scss" module>
.preview {
  width: 100%;
  height: 100%;
}
</style>
