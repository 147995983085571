import config from '@/config';

import httpService from '@/services/http.service';
import apiErrorUtils from '@/utils/apiErrors.utils';

const BASE_URL = config.lowcoApiUrl;

const mapError = (error) => {
  switch (error.code) {
    default:
      return 'errorMessages.general';
  }
};

const throwError = apiErrorUtils.makeThrowError(mapError);

const fetchFirstAvailableVouchers = (companyId, customerId) =>
  httpService
    .get(`${BASE_URL}/companies/${companyId}/customers/${customerId}/first-available-voucher`)
    .catch(throwError);

const useVoucher = (companyId, customerId, voucherId) =>
  httpService
    .put(`${BASE_URL}/companies/${companyId}/customer/${customerId}/vouchers/${voucherId}`)
    .catch(throwError);

const cancelLastUsedVoucher = (companyId, customerId) =>
  httpService
    .put(`${BASE_URL}/companies/${companyId}/customers/${customerId}/cancel-last-voucher-usage`)
    .catch(throwError);

export default {
  fetchFirstAvailableVouchers,
  useVoucher,
  cancelLastUsedVoucher,
};
