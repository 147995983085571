<template>
  <div class="pagination-container">
    <button @click="$emit('go-first')">
      <i class="icon-next rotate-next" />
      <i class="icon-next rotate-next" />
    </button>
    <button @click="$emit('go-previous')">
      <i class="icon-next rotate-next"></i>
    </button>
    <span>Page {{ current || 1 }} sur {{ total || 1 }}</span>
    <button @click="$emit('go-next')">
      <i class="icon-next"></i>
    </button>
    <button @click="$emit('go-last')">
      <i class="icon-next" />
      <i class="icon-next" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    current: Number,
    total: Number,
  },
};
</script>

<style lang="scss" scoped>
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotate-next {
  display: inline-block;
  transform: rotate(180deg);
}
</style>