<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import Modal from '@/components/common/Modal.vue';
import Button from '@/components/common/Button.vue';
import Pagination from '@/components/common/Pagination.vue';
import useCurrentCompany from '@/composables/useCurrentCompany';
import customersServices from '@/services/customers.services';
import companySubscriptionsService from '@/services/companySubscriptions.service';

import SearchCustomerResult from './SearchCustomerResult.vue';
import Spinner from '../common/Spinner.vue';

defineProps({
  visible: { type: Boolean, required: true },
});

defineEmits(['close']);

const { t } = useI18n();
const toast = useToast();

const { currentCompanyId } = useCurrentCompany();

const loading = ref(false);
const customers = ref([]);
const currentPage = ref(1);
const totalPages = ref(1);
const totalRecords = ref(0);
const downloadRef = ref(null);

watch(
  [currentCompanyId, currentPage],
  ([companyId, pageNumber], [, oldPageNumber]) => {
    if (pageNumber === oldPageNumber) {
      return;
    }

    const fetchLoyaltyCustomers = async (cpId, page = 1) => {
      try {
        loading.value = true;

        const {
          data,
          totalRecords: records,
          totalPages: pages,
        } = await customersServices.fetchLoyaltyCustomers(cpId, page, 20);

        customers.value = data;
        totalPages.value = pages;
        totalRecords.value = records;
      } catch (err) {
        const [error] = err;

        toast.error(t(error));
      } finally {
        loading.value = false;
      }
    };

    fetchLoyaltyCustomers(companyId, pageNumber);
  },
  { immediate: true },
);

const handleExportDataClick = async () => {
  try {
    const { fileUrl } = await companySubscriptionsService.exportCompanyLoyalty(
      currentCompanyId.value,
    );

    if (!fileUrl) {
      toast.error(t('components.loyaltyCustomersModal.errors.couldntDownload'));

      return;
    }

    downloadRef.value.href = fileUrl;
    downloadRef.value.click();
  } catch (err) {
    const [error] = err;

    toast.error(t(error));
  }
};

const goNextPage = () => {
  currentPage.value = Math.min(currentPage.value + 1, totalPages.value);
};

const goPreviousPage = () => {
  currentPage.value = Math.max(currentPage.value - 1, 0);
};
</script>

<template>
  <Modal
    :isDisplayed="visible"
    :title="t('components.loyaltyCustomersModal.title')"
    @closeModal="$emit('close')"
  >
    <div :class="$style.spinner">
      <Spinner v-if="loading" />
    </div>
    <template v-if="!loading && customers.length">
      <Button class="tar mb-2" @buttonClick="handleExportDataClick">
        {{ t('components.loyaltyCustomersModal.exportButton', { count: totalRecords }) }}
      </Button>

      <SearchCustomerResult :customers="customers">
        <template v-slot:title="{ c }">
          {{ c.lastname }} {{ c.firstname }} - {{ c.points }}
        </template>
      </SearchCustomerResult>

      <Pagination
        class="my-2"
        :current="currentPage"
        :total="totalPages"
        @go-next="goNextPage"
        @go-previous="goPreviousPage"
        @go-first="currentPage = 1"
        @go-last="currentPage = totalPages"
      />

      <a ref="downloadRef" class="hidden" href="#" download>
        Download
      </a>
    </template>
    <template v-if="!loading && !customers.length">
      {{ t('components.loyaltyCustomersModal.noCustomerFound') }}
    </template>
  </Modal>
</template>

<style lang="scss" module>
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
