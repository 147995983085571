<script setup>
import { defineEmits, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import QrCodeScanner from '@/components/common/QrCodeScanner.vue';
import Button from '@/components/common/Button.vue';

import AddCustomerVisitModal from './AddCustomerVisitModal.vue';
import SearchCustomerModal from './SearchCustomerModal.vue';
import CreateCustomerModal from './CreateCustomerModal.vue';
import LoyaltyCustomersModal from './LoyaltyCustomersModal.vue';

const modalConstants = {
  ADD_CUSTOMER_VISIT: 'addCustomerVisit',
  SEARCH_CUSTOMER: 'searchCustomer',
  CREATE_CUSTOMER: 'createCustomer',
  LOYALTY_CUSTOMERS: 'loyaltyCustomers',
};

const emit = defineEmits(['scan']);

const { t } = useI18n();

const result = ref(null);
const showScanConfirmation = ref(false);
const activeModal = ref(null);

watch(result, (value) => {
  if (!value) {
    return;
  }

  activeModal.value = modalConstants.ADD_CUSTOMER_VISIT;
});

watch(activeModal, (value) => {
  if (value === modalConstants.ADD_CUSTOMER_VISIT) {
    return;
  }

  result.value = '';
  showScanConfirmation.value = false;
});

const onDecode = (content) => {
  result.value = content;
  showScanConfirmation.value = true;
};

const handleOnRead = (data) => {
  emit('scan', { ...data, customerId: result.value });

  activeModal.value = null;
};
</script>

<template>
  <div>
    <h1 class="mb-4 tac">{{ t('components.loyaltyCardQrCodeReader.title') }}</h1>
    <h3 v-if="showScanConfirmation" :class="$style.scanSucceedSub">
      {{ t('components.loyaltyCardQrCodeReader.scanSucceed') }}
    </h3>

    <div :class="$style.wrapper">
      <QrCodeScanner :paused="activeModal !== null" @decode="onDecode" />

      <div :class="$style.buttons">
        <Button @buttonClick="activeModal = modalConstants.SEARCH_CUSTOMER">
          {{ t('components.loyaltyCardQrCodeReader.searchCustomerButton') }}
        </Button>

        <Button @buttonClick="activeModal = modalConstants.CREATE_CUSTOMER">
          {{ t('components.loyaltyCardQrCodeReader.createCustomerButton') }}
        </Button>
      </div>

      <Button class="mt-1 tac" @buttonClick="activeModal = modalConstants.LOYALTY_CUSTOMERS">
        {{ t('components.loyaltyCardQrCodeReader.loyaltyCustomersButton') }}
      </Button>
    </div>

    <AddCustomerVisitModal
      v-if="activeModal === modalConstants.ADD_CUSTOMER_VISIT"
      visible
      :customerId="result"
      @close="activeModal = null"
      @submit="handleOnRead"
    />

    <SearchCustomerModal
      v-if="activeModal === modalConstants.SEARCH_CUSTOMER"
      visible
      @close="activeModal = null"
      @submit="onDecode"
    />

    <CreateCustomerModal
      v-if="activeModal === modalConstants.CREATE_CUSTOMER"
      visible
      @close="activeModal = null"
      @submit="onDecode"
    />

    <LoyaltyCustomersModal
      v-if="activeModal === modalConstants.LOYALTY_CUSTOMERS"
      visible
      @close="activeModal = null"
    />
  </div>
</template>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.scanSucceedSub {
  font-weight: normal;
  color: $main-color;
  text-align: center;
  margin-bottom: 1rem;

  font-family: 'Roboto', sans-serif;
  text-transform: none;
  font-size: 2rem;
}

.wrapper {
  width: 100%;

  position: relative;

  @include sm {
    margin: 0 auto;

    width: 50rem;
    height: 50rem;
  }
}

.buttons {
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  @include spacing-children('vertical', 1rem);

  @include xs {
    flex-direction: row;
    justify-content: center;

    @include spacing-children('vertical', 0);
    @include spacing-children('horizontal', 1rem);
  }
}
</style>
