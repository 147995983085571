<template>
  <div class="divider"></div>
</template>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";

.divider {
  position: relative;
  width: 100%;
  height: 1%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 5%;
    right: 5%;
    width: 90%;
    height: 1px;

    background-image: linear-gradient(
      to right,
      transparent,
      $dark-green,
      transparent
    );
  }
}
</style>
