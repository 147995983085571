<template>
  <Layout>
    <Modal
      :isDisplayed="!canAccess"
      :title="t('pages.loyaltyCardReader.cannotAccess.title')"
      @closeModal="$router.push(routesConstants.HOME)"
    >
      <p>{{ t('pages.loyaltyCardReader.cannotAccess.description') }}</p>

      <div :class="$style.cannotAccessModalButtons">
        <LinkButton small error :to="routesConstants.HOME">{{ t('common.cancel') }}</LinkButton>
        <LinkButton small :to="`${routesConstants.OFFERS}?type=${companyOffersConstants.LOYALTY_CARD}`">
          {{ t('pages.loyaltyCardReader.cannotAccess.setupLoyaltyModal') }}
        </LinkButton>
      </div>
    </Modal>
    <template v-if="canAccess">
      <Button
        v-if="!!lastAddedVisit"
        class="mb-2 tac"
        isDanger
        @buttonClick="showVisitCancellation = true"
      >
        {{ t('pages.loyaltyCardReader.cancelLastVisitButton') }}
      </Button>
      <LoyaltyCardQrCodeReader @scan="handleVisitCreation" />
      <Modal
        :isDisplayed="showVisitCancellation"
        :title="t('pages.loyaltyCardReader.cancelLastVisitTitle')"
        @close-modal="showVisitCancellation = false"
      >
        <p v-html="t('pages.loyaltyCardReader.cancelLastVisitDescription')"></p>
        <div :class="$style.modalButtons">
          <Button isDanger @buttonClick="handleLastVisitCancellation">
            {{ t('common.confirm') }}
          </Button>
          <Button @buttonClick="showVisitCancellation = false">{{ t('common.cancel') }}</Button>
        </div>
      </Modal>
    </template>
  </Layout>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';

import Layout from '@/components/common/Layout.vue';
import Button from '@/components/common/Button.vue';
import LinkButton from '@/components/common/LinkButton.vue';
import Modal from '@/components/common/Modal.vue';
import LoyaltyCardQrCodeReader from '@/components/offers/LoyaltyCardQrCodeReader.vue';
import useCurrentCompany from '@/composables/useCurrentCompany';
import customerVisitService from '@/services/customerVisit.service';
import customerVouchersService from '@/services/customerVouchers.service';
import useCompanyOffers from '@/composables/useCompanyOffers';
import loyaltyCardStatusConstants from '@/constants/loyaltyCardStatus.constants';
import routesConstants from '@/constants/routes.constants';
import companyOffersConstants from '@/constants/companyOffers.constants';

const LoyaltyCardReader = defineComponent({
  components: {
    Layout,
    Button,
    LinkButton,
    Modal,
    LoyaltyCardQrCodeReader,
  },
  setup() {
    const { t } = useI18n();
    const toast = useToast();

    const { currentCompanyId } = useCurrentCompany();
    const { loyaltyCardStatus } = useCompanyOffers();

    const showVisitCancellation = ref(false);
    const lastAddedVisit = ref(null);
    const lastVoucherId = ref(null);

    const canAccess = computed(() => loyaltyCardStatus.value === loyaltyCardStatusConstants.IS_LOYALTY);

    const handleVisitCreation = async (data) => {
      const { paidPrice, customerId, voucherId } = data;

      try {
        lastVoucherId.value = voucherId;

        lastAddedVisit.value = await customerVisitService.addVisit(
          currentCompanyId.value,
          customerId,
          paidPrice,
          voucherId,
        );

        toast.success(t('pages.loyaltyCardReader.visitCreationSuccess'));
      } catch (err) {
        const [error] = err;

        toast.error(t(error));
      }
    };

    const handleLastVisitCancellation = async () => {
      const { companyId, customerId, id } = lastAddedVisit.value;

      try {
        await customerVisitService.cancelLastVisit(companyId, customerId, id);

        if (lastVoucherId.value) {
          await customerVouchersService.cancelLastUsedVoucher(companyId, customerId);
        }

        lastAddedVisit.value = null;
        lastVoucherId.value = null;
        showVisitCancellation.value = false;
      } catch (err) {
        const [error] = err;

        toast.error(t(error));
      }
    };

    return {
      t,
      showVisitCancellation,
      lastAddedVisit,
      canAccess,
      routesConstants,
      companyOffersConstants,
      handleVisitCreation,
      handleLastVisitCancellation,
    };
  },
});

export default LoyaltyCardReader;
</script>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.modalButtons {
  margin-top: 2rem;

  display: flex;
  justify-content: flex-end;

  @include spacing-children('horizontal', 1rem);
}

.cannotAccessModalButtons {
  margin-top: 2rem;
  
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include spacing-children('vertical', 1rem);

  @include sm {
    flex-direction: row;
    justify-content: flex-end;

    @include spacing-children('vertical', 0);
    @include spacing-children('horizontal', 1rem);
  }
}
</style>
