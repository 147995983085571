<script setup>
import { defineProps, defineEmits, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  customers: { type: Array, required: true },
  selectable: { type: Boolean, required: false },
});

const emit = defineEmits(['customer-click']);

const { selectable } = toRefs(props);

const { t } = useI18n();

const handleCustomerClick = (customer) => {
  if (!selectable.value) {
    return;
  }

  emit('customer-click', customer);
}
</script>

<template>
  <ul v-if="customers.length">
    <li v-for="customer of customers" :key="customer.id" :class="$style.row">
      <div :class="$style.customer">
        <div :class="$style.customerTitle">
          <slot name="title" :c="customer" />
        </div>
        <div :class="$style.customerEmail">
          <slot name="subTitle" :c="customer" />
        </div>
      </div>
      <button v-if="selectable" :class="$style.chooseButton" @click="handleCustomerClick(customer)">
        {{ t('common.choose') }}
      </button>
    </li>
  </ul>
  <div v-else>
    {{ t('components.searchCustomerResult.noCustomer') }}
  </div>
</template>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.row {
  padding: 0.8rem;

  display: flex;
  flex-direction: column;

  @include spacing-children('vertical', 1rem);

  &:nth-child(even) {
    background-color: $dark-white;
  }

  @include xs {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include spacing-children('vertical', 0);
    @include spacing-children('horizontal', 3rem);
  }
}

.customer {
  .customerTitle {
    font-weight: bold;
  }

  .customerEmail {
    font-size: 1.4rem;
    overflow-wrap: break-word;
  }
}

.chooseButton {
  padding: 0 0.8rem;
  background-color: $dark-green;
  color: $white;
  border-radius: 5px;

  align-self: flex-end;
}
</style>
