<script setup>
import { defineProps, defineEmits, ref, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import Modal from '@/components/common/Modal.vue';
import Spinner from '@/components/common/Spinner.vue';
import customersServices from '@/services/customers.services';
import useCurrentCompany from '@/composables/useCurrentCompany';
import useDebounce from '@/composables/useDebounce';

import SearchCustomerResult from './SearchCustomerResult.vue';
import FormGroup from '../common/FormGroup.vue';
import Divider from '../common/Divider.vue';
import Button from '../common/Button.vue';
import apiErrorsConstants from '@/constants/apiErrors.constants';

const initialFormData = {
  firstname: '',
  lastname: '',
  email: '',
};

defineProps({
  visible: { type: Boolean, required: true },
});

const emit = defineEmits(['close', 'submit']);

const { t } = useI18n();
const toast = useToast();

const { currentCompanyId } = useCurrentCompany();

const loading = ref(false);
const formData = ref({ ...initialFormData });
const legacyCustomers = ref([]);
const selectedCustomer = ref(null);
const isDirty = ref(false);
const duplicateCustomerError = ref(false);

const isValid = computed(() => {
  const { firstname, lastname, email } = formData.value;

  return !!firstname.trim() && !!lastname.trim() && !!email.trim();
});

watch(selectedCustomer, (value) => {
  if (!value) {
    formData.value = initialFormData;

    return;
  }

  formData.value = {
    firstname: value.firstname || '',
    lastname: value.lastname || '',
    email: value.email || '',
  };
});

watch(duplicateCustomerError, (value) => {
  if (!value) {
    return;
  }

  toast.error(t('components.createCustomerModal.customerAlreadyExists'));
});

const searchLegacy = async (searchParameters) => {
  duplicateCustomerError.value = false;
  isDirty.value = true;
  
  const { firstname, lastname, email } = searchParameters;

  if (!firstname.trim() && !lastname.trim() && !email.trim()) {
    legacyCustomers.value = [];

    return;
  }

  try {
    legacyCustomers.value = await customersServices.searchLegacyCustomers(
      currentCompanyId.value,
      firstname,
      lastname,
      email,
    );
  } catch {
    // Ignore for now
  }
};

const decouncedSearchLegacy = useDebounce(searchLegacy);

const inviteCustomer = async () => {
  if (!isValid.value) {
    return;
  }

  try {
    loading.value = true;
    const addedCustomer = await customersServices.inviteCustomer(
      currentCompanyId.value,
      formData.value,
      selectedCustomer.value?.id,
    );

    emit('submit', addedCustomer.id);
  } catch (err) {
    const [error] = err;

    if (error === apiErrorsConstants.CUSTOMER_ALREADY_EXISTS) {
      duplicateCustomerError.value = true;

      return;
    }

    toast.error(t(error));
  } finally {
    loading.value = false;
  }
};
</script>

<template>
  <Modal
    :isDisplayed="visible"
    :title="t('components.createCustomerModal.title')"
    :contentClassname="$style.modalContent"
    @closeModal="$emit('close')"
  >
    <div v-if="loading" :class="$style.spinnerWrapper">
      <Spinner />
    </div>

    <div v-show="!loading">
      <form :class="$style.form" @submit.prevent="inviteCustomer">
        <FormGroup
          name="firstname"
          :label="t('components.searchCustomerModal.fields.firstname')"
          changeEventStrategy="input"
          v-model="formData.firstname"
          @update:modelValue="decouncedSearchLegacy(formData)"
        />
        <FormGroup
          name="lastname"
          :label="t('components.searchCustomerModal.fields.lastname')"
          changeEventStrategy="input"
          v-model="formData.lastname"
          @update:modelValue="decouncedSearchLegacy(formData)"
        />
        <FormGroup
          name="email"
          :label="t('components.searchCustomerModal.fields.email')"
          changeEventStrategy="input"
          v-model="formData.email"
          @update:modelValue="decouncedSearchLegacy(formData)"
        />
        <Button class="mt-2 tar" type="submit" :disabled="!isValid">
          {{ t('common.add') }}
        </Button>
      </form>
      <Divider v-if="isDirty" class="mt-2 mb-4" />
      <div v-if="selectedCustomer" class="mt-4">
        <p>{{ t('components.createCustomerModal.youChoseLegacy') }}</p>
        <button :class="$style.cancelButton" @click="selectedCustomer = null">
          {{ t('common.cancel') }}
        </button>
      </div>

      <div v-if="duplicateCustomerError">
        {{ t('components.createCustomerModal.customerAlreadyExists') }}
      </div>
      <SearchCustomerResult
        v-else-if="isDirty"
        selectable
        :customers="legacyCustomers"
        @customerClick="selectedCustomer = $event"
      >
        <template v-slot:title="{ c }">
          {{ `${c.lastname} ${c.firstname} (${c.points} pts)` }}
        </template>
        <template v-slot:subTitle="{ c }">
          <span v-if="c.postalCode">{{ `${c.postalCode} ` }}</span>
          <span v-if="c.email">{{ c.email }}</span>
        </template>
      </SearchCustomerResult>
    </div>
  </Modal>
</template>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.modalContent {
  width: 100%;

  @include xs {
    width: 50rem;
  }
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  @include spacing-children('vertical', 1rem);
}

.cancelButton {
  margin-top: 1rem;

  padding: 0 0.8rem;
  background-color: $dark-green;
  color: $white;
  border-radius: 5px;

  display: block;
  margin-left: auto;
}
</style>
