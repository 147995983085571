import config from '@/config';

import httpService from '@/services/http.service';
import apiErrorUtils from '@/utils/apiErrors.utils';

const BASE_URL = config.lowcoApiUrl;

const mapError = (error) => {
  switch (error.code) {
    default:
      return 'errorMessages.general';
  }
};

const throwError = apiErrorUtils.makeThrowError(mapError);

const addVisit = (companyId, customerId, paidPrice, voucherId = null) =>
  httpService
    .post(
      `${BASE_URL}/companies/${companyId}/customers/${customerId}/visits-history`,
      { paidPrice, voucherId },
    )
    .catch(throwError);

const cancelLastVisit = (companyId, customerId, visitId) => httpService
  .remove(`${BASE_URL}/companies/${companyId}/customers/${customerId}/visits-history/${visitId}`)
  .catch(throwError);

export default {
  addVisit,
  cancelLastVisit,
};
