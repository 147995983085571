<template>
  <Modal :isDisplayed="visible" @close-modal="$emit('close')">
    <h3 class="mb-2">
      {{ t('components.addCustomerVisitModal.clientPaidPrice') }}
    </h3>

    <div v-if="!!voucher">
      <p v-if="voucher.useGift">
        {{ t('components.addCustomerVisitModal.giftAvailable', [voucher.gift]) }}
      </p>
      <p v-else>
        {{ t('components.addCustomerVisitModal.voucherAvailable', [voucher.discountValue]) }}
      </p>

      <span v-if="voucher.useGift">{{ t('components.addCustomerVisitModal.useGift') }}</span>
      <span v-else>{{ t('components.addCustomerVisitModal.useVoucher') }}</span>
      <Toggle
        class="my-2"
        v-model="useVoucher"
        :leftText="t('common.no')"
        :rightText="t('common.yes')"
      />
    </div>

    <InputNumber
      v-model="price"
      :minValue="0"
      :maxValue="Number.MAX_SAFE_INTEGER"
      :interval="0.01"
      useDecimal
      changeEventStrategy="input"
    />

    <div v-if="priceWithVoucher !== null && priceWithVoucher !== price" class="mt-2">
      <p :class="$style.warnText" v-if="!!priceWithVoucherLeftover">
        {{
          `${t('common.warning')} ${t('components.addCustomerVisitModal.priceVoucherMoneyLoss', [
            priceWithVoucherLeftover,
          ])}`
        }}
      </p>
      <p>{{ t('components.addCustomerVisitModal.priceVoucherIncluded', [priceWithVoucher]) }}</p>
    </div>

    <div :class="['mt-2', $style.visitModalButtons]">
      <Button isDanger @buttonClick="$emit('close')">
        {{ t('common.close') }}
      </Button>
      <Button @buttonClick="handleQrCodeButtonClick">
        <FontAwesomeIcon :icon="faQrcode" />
      </Button>
      <Button :warn="!!priceWithVoucherLeftover" @buttonClick="handleSubmit">
        {{ t('common.confirm') }}
      </Button>
    </div>
  </Modal>
</template>

<script>
import { defineComponent, toRefs, ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Big from 'big.js';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faQrcode } from '@fortawesome/pro-solid-svg-icons';

import Modal from '@/components/common/Modal.vue';
import Button from '@/components/common/Button.vue';
import InputNumber from '@/components/common/InputNumber.vue';
import customerVouchersService from '@/services/customerVouchers.service';
import useCurrentCompany from '@/composables/useCurrentCompany';
import routesConstants from '@/constants/routes.constants';

import Toggle from '../common/Toggle.vue';

const AddCustomerVisitModal = defineComponent({
  components: {
    InputNumber,
    Button,
    Modal,
    Toggle,
    FontAwesomeIcon,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    customerId: {
      type: [String, null],
      required: true,
    },
  },
  emits: ['close', 'submit'],
  setup(props, { emit }) {
    const { customerId } = toRefs(props);

    const router = useRouter();
    const { t } = useI18n();
    const { currentCompanyId } = useCurrentCompany();

    const voucher = ref(null);
    const useVoucher = ref(true);
    const price = ref(0.0);

    const isPriceFieldDirty = ref(false);

    const priceWithVoucher = computed(() => {
      if (!isPriceFieldDirty.value) {
        return null;
      }

      if (!voucher.value || !useVoucher.value) {
        return null;
      }

      return Math.max(new Big(price.value).minus(voucher.value.discountValue), 0);
    });

    const priceWithVoucherLeftover = computed(() => {
      if (priceWithVoucher.value === null || priceWithVoucher.value > 0) {
        return null;
      }

      return Math.abs(new Big(price.value).minus(voucher.value.discountValue));
    });

    const unwatchPriceWatcher = watch(price, () => {
      isPriceFieldDirty.value = true;

      unwatchPriceWatcher();
    });

    watch(
      customerId,
      (value) => {
        if (!value) {
          return;
        }

        const fetchVoucher = async () => {
          try {
            voucher.value = await customerVouchersService.fetchFirstAvailableVouchers(
              currentCompanyId.value,
              customerId.value,
            );
          } catch {
            // Ignore
            useVoucher.value = false;
          }
        };

        fetchVoucher();
      },
      { immediate: true },
    );

    const handleSubmit = () => {
      const data = {
        paidPrice: price.value,
      };

      if (useVoucher.value) {
        data.voucherId = voucher.value?.id;
      }
      
      emit('submit', data);
    };

    const handleQrCodeButtonClick = () => {
      const targetRoute = router.resolve({
        path: routesConstants.PRINT_CLIENT_QRCODE,
        query: { customerId: customerId.value },
      });

      window.open(targetRoute.href, '_blank');
    };

    return {
      t,
      voucher,
      useVoucher,
      price,
      priceWithVoucher,
      priceWithVoucherLeftover,
      faQrcode,
      handleSubmit,
      handleQrCodeButtonClick,
    };
  },
});

export default AddCustomerVisitModal;
</script>

<style lang="scss" module>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.visitModalButtons {
  display: flex;
  justify-content: flex-end;

  @include spacing-children('horizontal', 1rem);
}

.warnText {
  color: $error;
  font-weight: bold;
}
</style>
