<script>
/* eslint-disable import/first */
const CHANGE_EVENT_STRATEGIES = ['input', 'change'];
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { defineProps, defineEmits } from 'vue';

defineProps({
  modelValue: { type: String, required: true },
  name: { type: String, required: true },
  label: { type: String, required: true },
  disabled: { type: Boolean, required: false },
  changeEventStrategy: {
    type: String,
    required: false,
    default: 'change',
    validator: (value) => CHANGE_EVENT_STRATEGIES.includes(value),
  },
  groupClassname: { type: [Object, String], required: false },
  inputClassname: { type: [Object, String], required: false },
  labelClassname: { type: [Object, String], required: false },
});

defineEmits(['update:modelValue']);
</script>

<template>
  <div :class="[$style.group, groupClassname]">
    <input
      :id="name"
      :name="name"
      type="text"
      :class="[$style.input, { [$style.disabled]: disabled }, inputClassname]"
      :value="modelValue"
      :disabled="disabled"
      v-on:[changeEventStrategy]="$emit('update:modelValue', $event.target.value)"
    />
    <label for="name" :class="labelClassname">
      {{ label }}
    </label>
  </div>
</template>

<style lang="scss" module>
.group {
  display: flex;
  flex-direction: column;
}

.input {
  order: 1;

  &.disabled {
    background-color: $light-gray;
    color: $dark-gray;
  }
}
</style>
